.memo-body {
  font-family: "McLaren", cursive;
  background: #eee;
  padding: 0 16px;
  height: inherit;
  min-height: 100vh;
  overflow: hidden; /* Hide scrollbars */

  header {
    background-color: #f5ba13;
    margin: auto -16px;
    padding: 16px 32px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);

    h1 {
      color: #fff;
      font-family: "McLaren", cursive;
      font-weight: 200;
    }
  }
}
