.pageNotFound {
  width: 100%;
  min-height: 100vh;
  text-align: center;
  padding-top: 200px;

  .pageNotFound-content {
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
  }
}
