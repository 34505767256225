.memo-form {
  position: relative;
  width: 480px;
  margin: 30px auto 20px auto;
  background: #fff;
  padding: 15px;
  border-radius: 7px;
  box-shadow: 0 1px 5px rgb(138, 137, 137);

  input,
  textarea {
    width: 100%;
    border: none;
    padding: 4px;
    outline: none;
    font-size: 1em;
    font-family: inherit;
    resize: none;
  }

  button {
    position: absolute;
    right: 18px;
    bottom: -18px;
    background: #f5ba13;
    color: #fff;
    border: none;
    border-radius: 50%;
    font-size: 0.8rem;
    padding: 0;
    width: 36px;
    height: 36px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    outline: none;
  }

  button:hover {
    background: #f7d26d;
  }
}
