.table {
  margin-bottom: 0;
  .rating-icon-td {
    width: 30%;
    height: 45px;
    .rating-icon {
      height: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .rating-value {
    text-align: center;
  }
}

.movie-poster-center {
  margin: 20px auto;
  display: block;
  width: 80%;
}

.movie-poster {
  margin-top: 10px;
  width: 100%;
}
