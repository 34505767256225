.footer {
  display: block;
  background-color: #000000;
  padding: 1rem;
  .footer-p {
    color: #8f8f8f;
    text-align: center;
    margin: 0;
  }
}
