.home-body {
  font-family: "Montserrat", sans-serif;
  min-height: 100vh;
  background-color: rgba(33, 37, 41);
  color: white;

  .nav-link {
    margin-right: 1rem;
  }

  .home-container {
    width: 100%;
    height: auto;
    margin: auto;
    height: inherit;
    // max-width: 600px;
    border-radius: 20px;
    padding: 1rem 5rem;

    h2 {
      font-size: 2.5rem;
      font-weight: 900;
      line-height: 1.5;
      margin-bottom: 1rem;
      color: white;
      text-decoration: underline;
    }

    h3 {
      font-size: 1.5rem;
      font-weight: 900;
      line-height: 1.5;
    }

    .card {
      background-color: black;
      margin: 2rem 1rem;
      // width: 50%;
      text-align: center;
      box-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.5);
      .card-title {
        margin: 2rem 0;
        font-size: 1.5rem;
      }
      .card-skill {
        margin-bottom: 1rem;
      }
      .card-link {
        color: white;
        font-size: 1rem;
      }
    }
    .home-intro {
      padding-top: 3rem;
      .home-intro-left {
        margin: auto;
        .home-intro-title {
          font-size: 2rem;
        }
        .home-intro-decription {
          margin-top: 2rem;
        }
      }
    }
    .home-porto {
      padding-top: 3rem;
    }

    .home-skill {
      padding-top: 3rem;
      .home-skill-card {
        transition: all 0.3s ease-in-out;
      }
      .home-skill-card:hover {
        transform: scale(1.1);
      }
      .html-card {
        box-shadow: 0px 0px 10px 3px rgb(255, 160, 51);
      }
      .css-card {
        box-shadow: 0px 0px 10px 3px rgb(51, 194, 255);
      }
      .scss-card {
        box-shadow: 0px 0px 10px 3px rgb(247, 141, 233);
      }
      .js-card {
        box-shadow: 0px 0px 10px 3px rgb(251, 255, 0);
      }
      .ts-card {
        box-shadow: 0px 0px 10px 3px rgb(51, 54, 255);
      }
      .react-card {
        box-shadow: 0px 0px 10px 3px rgb(51, 235, 255);
      }
      .node-card {
        box-shadow: 0px 0px 10px 3px rgb(116, 255, 51);
      }
      .git-card {
        box-shadow: 0px 0px 10px 3px rgb(255, 255, 255);
      }
      .mongodb-card {
        box-shadow: 0px 0px 10px 3px rgb(0, 109, 7);
      }
      .mysql-card {
        box-shadow: 0px 0px 10px 3px rgb(12, 73, 131);
      }
      .home-skill-img {
        height: 5rem;
        width: auto;
        margin: 1rem auto;
      }
    }

    .home-form {
      margin-top: 3rem;
      .form-control {
        background-color: rgba(0, 0, 0, 0) !important;
        color: white;
      }
      .form-control:focus {
        outline: none !important;
        border: 1px solid white;
        box-shadow: 0 0 5px 2px #719ece;
      }
      .toast-container {
        position: fixed !important;
        bottom: 0;
        .toast-header {
          background-color: rgb(81, 186, 132);
          color: white;
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .home-container {
    padding: 0.5rem !important;
    text-align: center;
    .home-intro-left {
      margin-bottom: 1rem !important;
    }
    .home-intro-right {
      padding: 2rem 4rem;
    }
    .card {
      width: 75% !important;
      margin: 1rem auto !important;
      .card-title {
        font-size: 1.5rem !important;
      }
      .card-text {
        font-size: 2rem !important;
      }
      .home-link {
        font-size: 1.2rem !important;
      }
    }
  }
}
