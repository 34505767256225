.todo-body {
  background-color: #ffdefa;
  min-height: 100vh;
  height: inherit;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: hsl(198, 1%, 29%);
  text-align: center;
  font-size: 130%;

  .todo-content {
    align-items: start;
    margin: 2rem 0;
    height: auto;
    min-height: 500px;
    max-width: 500px;
    min-width: 250px;
    background: white;
    background-image: url("https://www.transparenttextures.com/patterns/black-thread-light.png");
    background-size: 25px 25px;
    border-radius: 20px;
    box-shadow: 4px 3px 7px 2px #00000040;
    padding: 1rem;
    box-sizing: border-box;

    .todo-heading {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;

      h1 {
        font-family: "Architects Daughter", cursive;
        transform: rotate(2deg);
        padding: 0.2rem 1.2rem;
        border-radius: 20% 5% 20% 5%/5% 20% 25% 20%;
        background-color: #ffdefa;
        font-size: 1.5rem;
      }
    }

    .todo-form {
      input {
        box-sizing: border-box;
        background-color: transparent;
        padding: 0.7rem;
        border-bottom-right-radius: 15px 3px;
        border-bottom-left-radius: 3px 15px;
        border: solid 3px transparent;
        border-bottom: dashed 3px #ffdefa;
        font-family: "Architects Daughter", cursive;
        font-size: 1rem;
        color: hsla(260, 2%, 25%, 0.7);
        width: 70%;
        margin-bottom: 20px;
      }

      input:focus {
        outline: none;
      }

      button {
        padding: 0;
        border: none;
        font-family: "Architects Daughter", cursive;
        font-size: 1rem;
        text-decoration: none;
        box-shadow: 3px 3px #ffdefa;
        border-radius: 5px;
        cursor: pointer;
      }

      button span {
        background: #f1f5f8;
        display: block;
        padding: 0.5rem 1rem;
        border-radius: 5px;
        border: 2px solid hsl(198, 1%, 29%);
      }

      button span:hover {
        opacity: 0.7;
      }

      button:active {
        transform: translate(3px, 3px);
        box-shadow: none;
      }

      button span:active {
        background-color: #ffdefa;
      }
    }
    li {
      font-family: "Architects Daughter", cursive;
      text-align: left;
      position: relative;
      padding: 0.5rem;
      transition: 2s;
      word-wrap: break-word;

      .todo-img {
        position: relative;
        float: right;
        width: 25px;
        height: 25px;
        cursor: pointer;
      }
    }

    li span {
      cursor: pointer;
    }

    img:hover {
      transform: scale(1.1);
    }

    .todo-crossItem {
      text-decoration: line-through;
    }
  }
}
