@import "../index.scss";

#marvel-intro {
  background-color: $bgColor;
  color: $whiteColor;
  padding: 3% 15% 7%;

  .marvel-intro-heading {
    font-family: $titleFont;
    font-weight: 900;
    font-size: 3.5rem;
    line-height: 1.5;
    text-align: left;
  }

  .marvel-intro-description {
    margin-top: 100px;
    h3 {
      font-family: $titleFont;
      font-weight: 900;
      line-height: 1.5;
      text-align: center;
    }
  }
  .marvel-intro-game {
    display: flex;
    justify-content: center;
    margin-bottom: 5%;

    .marvel-game-btn {
      margin: 3%;
      border-radius: 10px;
      .marvel-game-btn-row {
        // margin: 3%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        .marvel-game-btn-img {
          width: 3rem;
          padding: 5% 0 5% 5%;
        }

        .marvel-game-btn-text {
          font-family: $titleFont;
          font-size: 1em;
          font-weight: 400;
        }
      }
    }

    .marvel-game-btn:hover .avengers-btn-img {
      animation: swift 1s;
    }

    @keyframes swift {
      0% {
        transform: rotateY(0deg);
      }
      50% {
        transform: rotateY(180deg);
      }
      100% {
        transform: rotateY(0deg);
      }
    }

    .marvel-game-btn:hover .ulton-btn-img {
      animation: rotation 1s;
    }

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }
  }

  .ironman1 {
    width: 70%;
    transform: rotate(15deg);
    position: relative;
    z-index: 0;
    display: block;
    margin-left: 15%;
    border-radius: 15%;
  }

  @media (max-width: 992px) {
    .marvel-intro-heading {
      text-align: center;
    }
    .ironman1 {
      transform: rotate(0deg);
      position: static;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
