@import "../index.scss";

.marvel-navbar {
  background-color: $bgColor;
  color: $whiteColor;
  padding: 3% 15% 3%;
  .navbar-brand {
    font-family: "Ubuntu", sans-serif;
    font-size: 2.5rem;
    font-weight: bold;
  }

  .nav-link {
    font-size: 1.3rem;
    font-family: $titleFont;
    font-weight: 100;
    margin-right: 1rem;
  }

  .dropdown-menu {
    background-color: #ef8172;
  }

  .dropdown-item {
    color: $whiteColor;
    font-family: $titleFont;
    font-weight: 400;
    a {
      color: inherit;
    }
  }
}
